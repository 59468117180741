@use '../../../styles/utils' as util;

.text_styles {
  font-family: var(--font-ttsupermolotneue);
  font-weight: 600;
  font-style: normal;
  text-transform: uppercase;
}

.display_row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.display_column {
  display: flex;
  flex-direction: column;
}

.container {
  @extend .display_row;
  gap: 30px;
  width: 100%;
  padding: 16px;
  border-radius: 20px;
  background: #191f40;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: stretch;
    gap: 16px;
  }

  @media (max-width: 900px) {
    flex-wrap: wrap;
  }

  .container_filters {
    @extend .display_row;
    gap: 16px;
    width: 100%;
    justify-content: space-between;

    @media (max-width: 600px) {
      flex-direction: column;
    }

    .container_filters_autocomplete {
      width: 100%;
      height: 60px;
      @include util.AdaptiveSize(max-width, 303px, 392px, 375px, 1440px);

      @media (max-width: 600px) {
        max-width: 100%;
      }
    }

    .container_filters_main_price {
      position: relative;
      @extend .display_column;
      padding: 10px 16px;
      height: 60px;
      width: 100%;
      border-radius: 12px;
      border: 1px solid rgba(147, 155, 194, 0.32);
      background: #252e5c;
      &:hover {
        border: 1px solid rgba(147, 155, 194, 0.64);
        transition: border 0.5s;
      }

      .container_filters_main_price_title {
        @extend .text_styles;
        color: #bdc2dc;
        font-size: 11px;
        line-height: 1.45;
        letter-spacing: 0.44px;
      }

      .container_filters_main_price_value {
        @extend .display_row;
        justify-content: space-between;

        .container_filters_main_price_text {
          margin-left: 2px;
          color: white;
        }
      }
      .container_filters_main_price_slider {
        position: absolute;
        bottom: -4px;
        left: 0;
        width: 100%;
        padding-inline: 5px;

        .container_filters_main_price_slider_line {
          color: var(--primary-color);
          padding: 0;
        }
      }
    }
  }

  .container_resetBtn {
    @extend .display_row;
    width: fit-content;
    gap: 8px;
    cursor: pointer;

    .container_resetBtn_text {
      @extend .text_styles;
      color: #bdc2dc;
      font-size: 12px;
      line-height: 2;
      letter-spacing: 0.48px;
    }
  }
}
