.form {
  width: 100%;
}

/* Стилизация скролла и его псевдоэлементов */
.select_scroll::-webkit-scrollbar {
  width: 5px; 
}

.select_scroll::-webkit-scrollbar-thumb {
  background-color: rgba(169, 169, 169, 0.3);
  border-radius: 20px;
}

/* Опционально: Стилизация скролл-бара при наведении */
.select_scroll::-webkit-scrollbar-thumb:hover {
  background-color: rgba(205, 205, 205, 0.3);
}

/* Опционально: Стилизация трека (фона) скролла */
.select_scroll::-webkit-scrollbar-track {
  background-color: #252E5C;
}